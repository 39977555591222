import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-logout',
  templateUrl: './modal-logout.component.html',
  styleUrl: './modal-logout.component.scss',
})
export class ModalLogoutComponent {
  constructor(private dialogRef: DynamicDialogRef) {}

  handleLogout(isLogout: boolean) {
    this.dialogRef.close({ isLogout: isLogout });
  }
}
