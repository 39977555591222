import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalLogoutComponent } from './modal-logout/modal-logout.component';
import { IUserProfile } from '../interface/auth.interface';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
  items!: MenuItem[];

  nameShort!: string;
  userProfile!: IUserProfile;

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService
  ) {
    this.nameShort = this.extractInitials(
      authService.getUserProfile().fullName
    );
    this.userProfile = authService.getUserProfile();
  }

  extractInitials(name: string): string {
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('');
    return initials.toUpperCase().substring(0, 2);
  }

  clickProfile(op: OverlayPanel) {
    this.router.navigate(['/user/profile']);
    op.hide();
  }

  clickLogout(op: OverlayPanel) {
    op.hide();
    const ref = this.dialogService.open(ModalLogoutComponent, {
      header: 'คุณต้องการที่จะออกจากระบบหรือไม่ ?',
      width: '30rem',
      modal: true,
      closable: false,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
    });

    ref.onClose.subscribe((resp) => {
      if (resp.isLogout) {
        this.logout();
      }
    });
  }
PageOrder() {
  if(this.userProfile.staffLevel === 'admin') {
    this.router.navigate(['/orders/list-order']);
  }
  else if(this.userProfile.staffLevel === 'qc') {
    this.router.navigate(['/orders/list-order-qc']);
  }
}
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
