<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar p-card shadow-none border-0 md:border-1 surface-border border-noround md:border-round-2xl ">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main mt-0 lg:mt-2 ">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
    <div class="layout-mask"></div>
</div>
