import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { log } from 'console';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor(public layoutService: LayoutService) {}

  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if(userProfile.staffLevel == 'SL-000002'){
      this.model = [
        {
          label: 'การจัดการ Orders',
          items: [
            {
              label: 'QC List',
              icon: 'pi pi-file-edit',
              routerLink: ['/orders/list-order-qc'],
            }
          ]
        }
      ];
    }
    else{
      this.model = [
        // {
        //   label: 'Home',
        //   items: [
        //     { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
        //   ],
        // },
  
        {
          label: 'การจัดการ Orders',
          items: [
            {
              label: 'Order List',
              icon: 'pi pi-fw pi-book',
              routerLink: ['/orders/list-order'],
            },
            {
              label: 'QC List',
              icon: 'pi pi-file-edit',
              routerLink: ['/orders/list-order-qc'],
            },
            {
              label: 'Order Stock',
              icon: 'pi pi-list',
              routerLink: ['/orders/stock-list'],
            },
          ],
        },
        {
          label: 'Staff',
          items: [
            {
              label: 'Staff List',
              icon: 'pi pi-users',
              routerLink: ['/staff/staff-list'],
            },
          ],
        },
        {
          label: 'Master',
          items: [
            {
              label: 'BR / PR Type',
              icon: 'pi pi-database',
              routerLink: ['/master/brpr-type-list'],
            },
          ],
        },
      ];
    }
  }
}
