<div class="layout-topbar shadow-none border-bottom-1 surface-border">
  <a class="layout-topbar-logo" (OnClick)="PageOrder()">
    <div class="flex w-full gap-2 align-items-center justify-content-center">
      <img
        class="hidden lg:flex"
        src="assets/images/logos/new-logo-sa.png"
        alt="logo"
      />
      <span class="font-bold text-blue-800">SA - TFMS</span>
    </div>
  </a>
  <button
    #menubutton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi pi-bars"></i>
  </button>
  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }"
  >
    <button
      class="flex lg:hidden p-link layout-topbar-button"
      (click)="clickProfile(op)"
    >
      <i class="pi pi-user"></i>
      <span>Profile</span>
    </button>
    <button class="p-link layout-topbar-button">
      <i class="pi pi-bell"></i>
      <span>Notification</span>
    </button>
    <button
      class="hidden lg:flex p-link layout-topbar-button"
      (click)="op.toggle($event)"
    >
      <div
        class="flex bg-primary-500 font-bold text-white border-circle w-3rem h-3rem align-items-center justify-content-center"
      >
        <div>{{ nameShort }}</div>
      </div>
    </button>
    <button
      class="flex lg:hidden p-link layout-topbar-button"
      (click)="clickProfile(op)"
    >
      <i class="pi pi-folder"></i>
      <span>On-hand projects</span>
    </button>
    <button
      class="flex lg:hidden p-link layout-topbar-button"
      (click)="clickLogout(op)"
    >
      <i class="pi pi-sign-out"></i>
      <span>Logout</span>
    </button>
  </div>
</div>

<p-overlayPanel #op>
  <ng-template pTemplate="content">
    <div
      class="flex flex-column w-12rem gap-2 align-items-center justify-content-center"
    >
      <div class="flex flex-column align-items-center justify-content-center">
        <div
          class="flex mb-2 w-3rem h-3rem bg-primary-500 font-bold text-white border-circle align-items-center justify-content-center"
        >
          <div>{{ nameShort }}</div>
        </div>
        <div class="font-bold text-lg">{{ userProfile.fullName }}</div>
        <div>{{ userProfile.staffLevel }}</div>
      </div>

      <div
        class="flex flex-column gap-2 w-full align-items-center justify-content-center"
      >
        <div
          class="flex flex-row px-2 py-1 w-full border-round hover:bg-primary-50 active:bg-primary-100 align-items-center justify-content-between cursor-pointer"
          (click)="clickProfile(op)"
        >
          <div class="flex flex-row gap-2 align-items-center">
            <i class="pi pi-user"></i>
            <span>Profile</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </div>
        <div
          class="flex flex-row px-2 py-1 w-full border-round hover:bg-primary-50 active:bg-primary-100 align-items-center justify-content-between cursor-pointer"
        >
          <div class="flex flex-row gap-2 align-items-center">
            <i class="pi pi-folder"></i>
            <span>On-hand projects</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </div>
        <div
          class="flex flex-row px-2 py-1 w-full border-round hover:bg-primary-50 active:bg-primary-100 align-items-center justify-content-between cursor-pointer"
          (click)="clickLogout(op)"
        >
          <div class="flex flex-row gap-2 align-items-center">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
