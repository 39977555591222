<div class="flex flex-column md:flex-row gap-2 justify-content-center">
  <div class="flex-order-1 md:flex-order-0 w-full">
    <button
      pButton
      type="submit"
      label="ยกเลิก"
      class="p-button-secondary w-full"
      (click)="handleLogout(false)"
    ></button>
  </div>
  <div class="flex-order-0 md:flex-order-1 w-full">
    <button
      pButton
      type="submit"
      label="ออกจากระบบ"
      class="p-button-danger w-full"
      (click)="handleLogout(true)"
    ></button>
  </div>
</div>
